<template>
    <div>
        <v-dialog v-model="dialog" max-width="500" persistent>
            <v-card>
                <v-card-title class="primary--text">
                    <v-icon class="mr-2 primary--text">{{ "mdi-filter" }}</v-icon>
                    {{ "Filtrage" }}
                    <v-spacer />
                    <v-btn icon class="v-btn--active" color="primary" @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="pa-6">
                    <form @submit.prevent="save">
                        <p class="font-weight-bold mb-2">
                            Inscription :
                        </p>
                        <v-menu v-model="startDateMenu" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="filter.startDate" label="Date de début *"
                                    prepend-inner-icon="mdi-calendar-outline" readonly v-bind="attrs" v-on="on" outlined
                                    background-color="white lighten-4" clearable></v-text-field>
                            </template>
                            <v-date-picker v-model="filter.startDate" @input="startDateMenu = false"></v-date-picker>
                        </v-menu>

                        <v-menu v-model="endDateMenu" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="filter.endDate" label="Date de fin *"
                                    prepend-inner-icon="mdi-calendar-outline" readonly v-bind="attrs" v-on="on" outlined
                                    background-color="white lighten-4" clearable></v-text-field>
                            </template>
                            <v-date-picker v-model="filter.endDate" @input="endDateMenu = false"></v-date-picker>
                        </v-menu>

                        <v-select
                            :loading="versionsLoading"
                            outlined
                            clearable
                            :items="versions"
                            v-model="filter.version"
                            label="Version"
                            hide-details
                                                        
                        />

                        <v-btn color="primary" class="rounded-lg text-none mt-4" large depressed @click="save" block>
                            Recherche
                        </v-btn>
                    </form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import { HTTP } from '@/http-common';
export default {
    data() {
        return {
            dialog: false,
            startDateMenu: false,
            endDateMenu: false,
            filter: {},

            versionsLoading: false,
            versions: []
        };
    },
    methods: {
        open() {
            this.dialog = true;

            if (!this.versions || this.versions.length == 0) {
                this.getVersions();
            }
        },
        close() {
            this.dialog = false;
        },
        save() {
            this.$emit("filter", this.filter);
            this.close();
        },

        getVersions() {
            this.versionsLoading = true;
            HTTP.get("v1/users/versions").then((res) => {

                this.versionsLoading = false;
                this.versions = res.data.data;

            }).catch((e) => {
                this.versionsLoading = false;
                console.log(e);
            });
        }
    },
};
</script>
  
  