<template>
    <div>
        <v-dialog v-model="dialog" persistent scrollable width="500">
            <form @submit.prevent="save">
                <v-card :disabled="btnLoading" :loading="btnLoading">
                    <v-card-title>
                        Importer des utilisateurs
                        <v-spacer/>
                        <v-icon @click="close">mdi-close</v-icon>
                    </v-card-title>

                    <v-card-subtitle>
                        Cette opération peut créer ou mettre à jour s'il existe.
                    </v-card-subtitle>

                    <v-divider/>

                    <v-card-text class="pa-4">

                        <v-alert v-if="error" border="left" prominent text type="warning">
                            {{ error }}
                        </v-alert>

                        <v-file-input ref="file"
                                      :error-messages="errors.excel_file"
                                      dense
                                      filled
                                      label="Fichier excel *"
                                      rounded
                                      @change="uploadFile($event)"></v-file-input>

                        <v-alert border="left"
                                 color="info"
                                 prominent
                                 text>
                            Veuillez respecter ce format excel :

                            <span class="d-block py-2"><strong>Colonne A :</strong> Catégorie du compte </span>
                            <span class="d-block py-2"><strong>Colonne B :</strong> Nom du magasin </span>
                            <span class="d-block py-2"><strong>Colonne C :</strong> Nom complet </span>
                            <span class="d-block py-2"><strong>Colonne D :</strong> Pseudo </span>
                            <span class="d-block py-2"><strong>Colonne E :</strong> Pays </span>
                            <span class="d-block py-2"><strong>Colonne F :</strong> Numéro de téléphone </span>
                            <span class="d-block py-2"><strong>Colonne G :</strong> Email </span>
                            <span class="d-block py-2"><strong>Colonne H :</strong> Wilaya </span>
                            <span class="d-block py-2"><strong>Colonne I :</strong> Commune </span>
                            <span class="d-block py-2"><strong>Colonne J :</strong> Adresse  </span>
                            <span class="d-block py-2"><strong>Colonne K :</strong> Type de magasin  </span>
                            <span class="d-block py-2"><strong>Colonne L :</strong> Mot de passe </span>
                            <v-btn class="mt-2" color="info"
                                   dark
                                   depressed
                                   @click="download">
                                <v-icon left>mdi-download-outline</v-icon>
                                Exemple
                            </v-btn>
                        </v-alert>

                    </v-card-text>

                    <v-divider/>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn :loading="btnLoading"
                               class="rounded-lg"
                               color="primary"
                               depressed
                               type="submit"
                        >
                            <v-icon left>mdi-content-save</v-icon>
                            importer
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </form>
        </v-dialog>
    </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            dialog: false,
            btnLoading: false,
            excel_file: '',
            errors: {},
            error: null,
        };
    },
    methods: {
        open() {
            if (this.$refs.file) {
                this.$refs.file.reset();
                this.excel_file = '';
            }
            this.errors = {};
            this.error = null;
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
        save() {
            this.errors = {};
            this.error = null;
            this.btnLoading = true;
            let formData = new FormData();
            formData.append('excel_file', this.excel_file);

            HTTP.post("/v1/users/import", formData)
                .then((res) => {
                    this.btnLoading = false;
                    this.$successMessage = "Les données ont été importées avec succès !";
                    if (res.data.error) {
                        this.error = res.data.error
                    } else {
                        this.close();
                    }
                    this.$emit("refresh");
                })
                .catch((err) => {
                    this.errors = err.response.data.errors;
                    this.$errorMessage = "Une erreur s'est produite veuillez corriger.";
                    this.btnLoading = false;
                    console.log(err);
                });
        },
        uploadFile(file) {
            this.excel_file = file;
        },
        download() {
            let path = process.env.VUE_APP_FILE_URL + '/usersExample.xlsx'
            window.open(path, '_blanc')
        }
    },
};
</script>