<template>
  <div>
    <v-dialog v-model="dialog" scrollable width="600">
      <v-card :disabled="loading" :loading="loading">
        <v-card-title>
          {{ title }}
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="pa-8">

          <v-text-field
              v-model="salepoint.pos_name"
              :error-messages="errors.pos_name"
              label="Nom pos *"
          />

          <v-text-field
              v-model="salepoint.owner_name"
              :error-messages="errors.owner_phone"
              label="Nom du propriétaire *"
          />

          <v-text-field v-model="salepoint.owner_phone"
                        :error-messages="errors.owner_phone"
                        label="Téléphone du propriétaire *"
          />

          <v-select
              v-model="salepoint.salepoint_type_id"
              :error-messages="errors.salepoint_type_id"
              :items="types"
              item-text="name"
              item-value="id"
              label="Type *"
          />

          <v-row>
            <v-col cols="6">
              <v-autocomplete
                  v-model="salepoint.wilaya_id"
                  :error-messages="errors.wilaya_id"
                  :items="$store.state.wilayas"
                  @change="onWilayaChange"
                  item-text="name"
                  item-value="id"
                  label="Wilaya *"
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                  v-model="salepoint.commune_id"
                  :disabled="!salepoint.wilaya_id"
                  :error-messages="errors.commune_id"
                  :items="filteredCommunes"
                  item-text="name"
                  item-value="id"
                  label="Commune *"
              />
            </v-col>
          </v-row>

          <v-text-field
              v-model="salepoint.adress"
              :error-messages="errors.adress"
              label="Adresse *"
          />

          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="salepoint.latitude"
                  :error-messages="errors.latitude"
                  label="Latitude *"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="salepoint.longitude"
                  :error-messages="errors.longitude"
                  label="Longitude *"
              />
            </v-col>
          </v-row>


        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="isLoading"
                 color="primary"
                 depressed
                 @click="saveSalepoint">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      isLoading: false,
      loading: false,
      errors: {},
      salepoint: {
        wilaya_id: null,
        commune_id: null,
      },
      types: [],
      communes: [],
      dairas: [],
      title: '',
    }
  },
  computed: {
    filteredCommunes() {
      if (!this.salepoint.wilaya_id) {
        return [];
      }

      let dairaIds = this.dairas
          .filter(el => el.wilaya_id === this.salepoint.wilaya_id)
          .map(el => el.id);

      return this.communes.filter(commune => dairaIds.includes(commune.daira_id));
    }
  },
  methods: {
    onWilayaChange() {
      this.salepoint.commune_id = null;
    },
    open(item) {
      this.errors = {}
      if (item && item.salepoint) {
        this.salepoint = item.salepoint
      } else {
        this.salepoint = {}
      }

      this.salepoint['user_id'] = item.id

      this.title = item && item.salepoint ? "Modifier le compte POS" : "Ajouter un compte POS"
      this.dialog = true
      this.fetchAllParams()
    },
    saveSalepoint() {
      this.isLoading = true
      let url = this.salepoint && this.salepoint.id ? "/v1/users/update-salepoint" : "/v1/users/create-salepoint"
      HTTP.post(url, this.salepoint).then(() => {
        this.dialog = false
        this.isLoading = false
        this.$emit('refresh')
        this.$successMessage = "Cet élément a été enregistré avec succès.";
      }).catch(err => {
        this.errors = err.response.data.errors
        this.isLoading = false
      })
    },
    fetchAllParams() {
      this.loading = true
      Promise.all([
        HTTP.get("/salepoint-type"),
        HTTP.get("/commune/all"),
        HTTP.get("/commune/dairas")
      ])
          .then((responses) => {
            this.types = responses[0].data;
            this.communes = responses[1].data;
            this.dairas = responses[2].data;
            this.loading = false
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            this.loading = false
          });
    }
  }
}
</script>

<style scoped>

</style>