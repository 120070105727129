<template>
    <div>
        <v-dialog v-model="dialog"
                  max-width="500"
                  persistent>
            <v-card>
                <v-card-title>
                    Modifier le mot de passe
                    <v-spacer/>
                    <v-btn icon @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider/>
                <v-card-text class="pa-8">
                    <v-text-field :error-messages="errors.new_password"
                                  label="Nouveau mot de passe *"
                                  v-model="form.new_password"
                                  placeholder="ACB*/52"
                                  prepend-inner-icon="mdi-lock"/>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn :loading="isLoading"
                           :disabled="form.new_password.length < 6 || form.new_password.length > 6"
                           color="primary"
                           depressed
                           @click="handleSave">
                        <v-icon left>mdi-content-save</v-icon>
                        Enregistrer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {HTTP} from '@/http-common';

export default {
    data() {
        return {
            dialog: false,
            isLoading: false,
            form: {
                user_id : '',
                new_password : '',
            },
            errors: {},
        };
    },
    methods: {
        open(item) {
            this.dialog = true
            this.form.user_id = item.id
            this.form.new_password = ''
        },
        close() {
            this.dialog = false
        },
        handleSave() {
            this.isLoading = true
            this.errors = {}
            HTTP.post("v1/users/update-password", this.form).then(() => {
                this.isLoading = false
                this.$successMessage = "Le mot de passe a été modifié avec succès"
                this.close()
            }).catch((err) => {
                this.isLoading = false
                this.errors = err.response.data.errors
                console.log(err)
            });
        }
    },
};
</script>
  
  