<template>
  <div>
    <v-dialog v-model="dialog"
              max-width="500"
              persistent>
      <v-card>
        <v-card-title>
          Modifier le numéro de téléphone
          <v-spacer/>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-8">

          <v-alert color="info" dark border="bottom" v-if="phoneHistories.length">
            Ancient modification :
            <ul>
              <li v-for="(phone,i) in phoneHistories" :key="i">
                {{phone.old_phone}}
                <v-icon small>mdi-arrow-right</v-icon>
                {{phone.new_phone}}
              </li>
            </ul>
          </v-alert>

          <v-text-field v-model="form.phone"
                        :error-messages="errors.phone"
                        label="Nouveau numéro *"
                        prepend-inner-icon="mdi-phone"/>

          <v-textarea v-model="form.remark"
                      :error-messages="errors.remark"
                      label="Remarque(optionnel)"
                      rows="3"
          />
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn :disabled="!form.phone"
                 :loading="isLoading"
                 color="primary"
                 depressed
                 @click="handleSave">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from '@/http-common';

export default {
    data() {
        return {
            dialog: false,
            isLoading: false,
            form: {
                user_id: '',
                phone: '',
                remark: '',
            },
            phoneHistories : [],
            errors: {},
        };
    },
    methods: {
        open(item) {
            this.dialog = true
            this.phoneHistories = item.phone_histories
            this.form.user_id = item.id
            this.form.phone =  item.phone
            this.form.remark = ''
        },
        close() {
            this.dialog = false
        },
        handleSave() {
            this.isLoading = true
            this.errors = {}
            HTTP.post("v1/users/update-phone", this.form).then(() => {
                this.isLoading = false
                this.$successMessage = "Le numéro de téléphone a été modifié avec succès"
                this.close()
                let user = {
                    user_id: this.form.user_id,
                    phone: this.form.phone,
                }
                this.$emit('save', user)
            }).catch((err) => {
                this.isLoading = false
                this.errors = err.response.data.errors
                console.log(err)
            });
        }
    },
};
</script>
  
  